:root {
  --shadow-color: #7a5921;
}

body {
  margin: 0;
  padding: 0;
}
main {
  margin: 0px; 
  padding: 20px; 
  background-color: #FFEFD6;
}


.header {
  width: 100%; /*これより大きくすると見切れる。なぜ！！！！？？？？*/
  background-color: #FFEFD6;
  font-size: 22px;
  margin: 0px;
  padding: 0;
  z-index: 10;
  border-bottom: 0px solid rgb(243, 152, 0);
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; /* 3列の幅の比率を1:2:1に設定 */
  grid-template-rows: auto auto;
  justify-self: center; /* 水平方向の配置 */
  align-self: center;   /* 垂直方向の配置 */
}





.SearchBarContainer {
  margin: 0 0px; /* 左右のマージン */
  background-color: #ffffff;
  padding: 0px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%; /* コンテナの幅いっぱいに広がる */
  height: 50px; /*絶対指定*/
  justify-self: center;
  align-self: center;
  display: flex; /* フレックスコンテナを作成 */
  justify-content: space-between; /* 子要素間の間隔を均等に設定 */
}




.SearchBarToggle{
  width: 150%; /* アイコンのサイズ調整 */
  height: auto;
}

.SearchBarInput {
  border: none;
  outline: none;
  padding: 5px;
  /*height: 100%;*/
  width: 100%;
  justify-self: center;
  align-self: center;
  align-items: center;
}


.IconsContainer{
  align-self: center; 
  justify-self: center;
  display: flex;
  flex-flow: row;
  height: 100%;
  width: 100%;
  justify-content: flex-end; /* 水平方向の中央寄せ */
  padding-right: 4px;
}


.iconLink {
  margin: 3px;
  display: inline-flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 50px; /* 適切なサイズに設定 */
  height: 50px;
  border-radius: 20%; /* 円形 */
  background-color: transparent; /* 透明背景 */
  text-decoration: none; /* リンクの下線を削除 */
  border: none;
  cursor: pointer;
  background-color: #f39800;
  box-shadow: 4px 4px 6px rgb(from var(--shadow-color) r g b / 0.3);
}

.iconLink:hover {
  background-color: rgba(0, 0, 0, 0.1); /* マウスオーバー時の色変更 */
}

.iconLink img {
  width: 80%; /* アイコンのサイズ調整 */
  height: auto;
}

.iconSearchbar {
  margin: 7px;
  display: inline-flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 32px; /* 適切なサイズに設定 */
  height: 32px;
  border-radius: 20%; /* 円形 */
  background-color: transparent; /* 透明背景 */
  text-decoration: none; /* リンクの下線を削除 */
  border: none;
  cursor: pointer;
  background-color: #ffffff;
}


.overlay {
  display: flex;
  overscroll-behavior: contain;
  overflow-y: scroll;
  position:fixed;
  z-index: 100;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0, 0, 0, 0.5);
  /*スクロールバー非表示（IE・Edge）*/
  -ms-overflow-style: none;
  /*スクロールバー非表示（Firefox）*/
  scrollbar-width: none;
}
/*スクロールバー非表示（Chrome・Safari）*/
.overlay::-webkit-scrollbar{
  display:none;
}

.nonScroll {
  height:calc(100vh + 1px) ;
  width: 1px;
  background-color: transparent;
}

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 110;
  width: 80%; /* 画面幅の80% */
  height : 80vh;
  margin: 20px auto; /* 上下のマージンと自動で中央揃え */
  padding: 20px; /* 内側のパディング */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19); /* 影をつける */
  border-radius: 5px; /* 角を丸くする */
  min-height: 50vh; /* フレームの最小高さをビューポートの高さの50%に設定 */
  overflow-y: auto; /* 縦方向に内容がはみ出る場合にスクロールバーを表示 */
}

.closeButton {
  display: block;
  position: fixed;
  z-index: 120;
  top: 15%;
  right: 14%;
  width: 40px;
  height: 40px;
  border: 2px solid #7A5921; /* 枠の調整 */
  border-radius: 50%;  /* 丸みの度合い */
  background-color:rgba(255, 255, 255, 0.7);
}

.closeButton::before, .closeButton::after { /* 共通設定 */
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px; /* 棒の幅（太さ） */
  height: 30px; /* 棒の高さ */
  background: #7A5921;
}
 
.closeButton::before {
  transform: translate(-50%,-50%) rotate(45deg);
}
 
.closeButton::after {
  transform: translate(-50%,-50%) rotate(-45deg);
}


.logoImage {
  width: 260px;
  height: 50px;
  object-fit: contain;
  margin: 5px;
  padding: 0px;
  display: flex;
  align-self: start; /* 上寄せ */
  justify-self: start; /* 左寄せ */
}




/* PCなどの大画面用のスタイル */
@media (min-width: 768px) {
  .logoImage{
    grid-column: 1;
    grid-row: 1;
  }

  .SearchBarContainer{
    grid-column: 2/6;
    grid-row: 1;
    margin: 5px;
    align-self: flex-end;
  }

  .IconsContainer{
    grid-column: 6;
    grid-row: 1; 
  }
}


/* モバイルなどの小画面用のスタイル */
@media (max-width: 767px) {
  .logoImage{
    grid-column: 1;
    grid-row: 1;
    width: 60vw;
  }

  .SearchBarContainer{
    grid-column: 1/6;
    grid-row: 2;
    margin: 1px;
    align-self: left;
  }

  .IconsContainer{
    grid-column: 6;
    grid-row: 2;
    align-items: flex-end; /* コンテナの下に寄せる */
  }

  .iconLink{
    width: 10vw; /* 適切なサイズに設定 */
    height: 10vw;
  }
}