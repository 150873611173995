
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.button {
  padding: 1rem;
  margin: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.animationBox {
  width: 100px;
  height: 100px;
  background-color: tomato;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  transition: all 0.5s ease;
}
