.textInput {
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 16px;
  outline: none;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.textInput:focus {
  border-color: rgb(245, 155, 0);
  box-shadow: 0 0 8px rgba(245, 155, 0, 0.6);
}

.textInput::placeholder {
  color: #adb5bd;
}

.suggestions {
  border: 1px solid #ddd;
  list-style: none;
  padding: 0;
  margin-top: 0;
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
}
.activeSuggestion {
  background-color: #007bff;
  color: white;
}

.width-S {
  width: 25%;
}

.width-M {
  width: 50%;
}

.width-L {
  width: 100%;
}