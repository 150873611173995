:root {
  --background-white: #ffffff;
  --background-orange: #ffefd6;
  --accent-color: #f39800;
  --text-color: #7A5921;
  --shadow-color: #7a5921;
}

.dropDown {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 10px 0;
  }

.versionDropDown {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  margin: 10px 0;
  color: rgb(243, 152, 0);
  box-shadow: 4px 4px 6px rgb(from var(--shadow-color) r g b / 0.3);
  font-size: 20px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.versionOption {
  /* 折り返し許可 */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}