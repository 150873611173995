/* レスポンシブデザイン デバイスによって大きさが変わる*/
.responsiveBox {
  width: 100%;
  height: 200px;
  background-color: tomato;
  color: white;
  text-align: center;
  line-height: 200px;
}

/* アダプティブデザイン 画面幅によって大きさの調整方法が変わる*/
.adaptiveBox {
  width: 300px;
  height: 300px;
  background-color: lightblue;
  color: white;
  text-align: center;
  line-height: 300px;
}
@media only screen and (max-width: 600px) {
  .adaptiveBox {
    width: 200px;
    height: 200px;
    line-height: 200px;
  }
}

/* リキッドデザイン 画面の大きさによって変わる*/
.liquidBox {
  width: 50%;
  height: 300px;
  background-color: lightgreen;
  color: white;
  text-align: center;
  line-height: 300px;
}

/* ソリッドデザイン 固定幅*/
.solidBox {
  width: 300px;
  height: 300px;
  background-color: lightcoral;
  color: white;
  text-align: center;
  line-height: 300px;
}

/* 課題用css */
.main {
  display: flex;
  justify-content: space-between;
}

.sidebar {
  width: 30%;
  min-width: 120px;
  background-color: #bdbf92;
  margin: 10px 10px;
  padding: 30px 10px;
}

.image {
  width: 60%;
  display: block;
  margin: auto;
}

.content {
  margin: 10px 10px;
  padding: 10px 10px;
  background-color: #aabc64;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    width: 150px
  }

  .image {
    width: 360px;
    max-width: 80%;
  }
}