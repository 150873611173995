.Icon {
    /*width: 50px;*/
    display: flex;
    max-width: calc(50%);
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    background-color: #ffefd6;
    margin: 10 auto;
}

.Loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.LoadingIconWrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.LoadingIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.hatakeContainer{
    display:flex;
}

h2, p {
    color: var(--text-color);
}