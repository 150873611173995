.card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 16px;
}



.title {
    font-size: 1.5rem;
    margin: 16px;
}

.description {
    font-size: 1rem;
    margin: 16px;
    color: #333;
}
.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
}

.closeButton {
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}