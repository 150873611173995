.radioButtonsContainer {
  display: flex;
  flex-direction: column; /* これはJavaScriptによって上書きされる */
  margin: 10px 0;
  border: 2px solid #ddd;
  padding: 10px;
  border-radius: 8px;
}

.radioButtonLabel {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 25px;
  cursor: pointer;
}

.radioButton {
  margin-right: 5px;
  cursor: pointer;
}

/* 以下はカスタマイズの例です */
/* ラジオボタンのカスタムスタイル */
.radioButton:checked {
  background-color: #4CAF50;
  border-color: #4CAF50;
}

/* ラベルのカスタムスタイル */
.radioButtonLabel {
  font-size: 16px;
  color: #333;
}