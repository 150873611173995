.iconWrapper {
    background-color: var(--accent-color);
    align-items: center;
    border-radius: 0.5rem;
    box-shadow: 4px 4px 4px rgb(from var(--shadow-color) r g b / 0.4);
    width: 100%;
    max-width: 100px;
}

.icon {
    width: 100%;
    vertical-align: middle;
    object-fit: contain;
}