/* CheckBoxes.module.css */

.checkBoxesContainer {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}
  
.checkBoxLabel {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.checkBox {
  margin-right: 10px;
  cursor: pointer;
}
