/* レスポンシブデザイン */
.responsiveBox {
  width: 100%;
  height: 200px;
  background-color: tomato;
  color: white;
  text-align: center;
  line-height: 200px;
}

/* アダプティブデザイン */
.adaptiveBox {
  width: 300px;
  height: 300px;
  background-color: lightblue;
  color: white;
  text-align: center;
  line-height: 300px;
}
@media only screen and (max-width: 600px) {
  .adaptiveBox {
    width: 200px;
    height: 200px;
    line-height: 200px;
  }
}

/* リキッドデザイン */
.liquidBox {
  width: 50%;
  height: 300px;
  background-color: lightgreen;
  color: white;
  text-align: center;
  line-height: 300px;
}

/* ソリッドデザイン */
.solidBox {
  width: 300px;
  height: 300px;
  background-color: lightcoral;
  color: white;
  text-align: center;
  line-height: 300px;
}
