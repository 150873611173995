:root {
    --background-white: #ffffff;
    --background-orange: #ffefd6;
    --accent-color: #f39800;
    --text-color: #7A5921;
    --shadow-color: #7a5921;
}

.scoreCard {
    padding: 0 auto;
    display: flex;
    flex-wrap: wrap;
    vertical-align: center;
    justify-content: center;
}
@media (max-width: 783px) {
    .scoreCardWrapper {
      width: 100%;
      max-width: 450px;
    }
  }

.basic {
    background-color: #ffefd6;
    min-height: 100vh; /* ビューポートの高さの100% */
}

.pageText{
    text-align: center;
}