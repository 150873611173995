.ytContainer {
    position: relative;
    width: 100%;
}

.ytContainer ::before {
    content: "";
    display: block;
    padding-top: 56.25%;
}

.iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video {
    /*grid-area: 8 / 1;*/
    justify-self: start;
}

.novideo {
    grid-area: 8 / 1 / 9 / 6;
    justify-self: center;
    align-self: center;
}
