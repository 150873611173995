.header {
  font-size: 2rem;
  color: darkblue;
}

.link {
  color: green;
}

.imageStyle {
  width: 200px;
  height: auto;
}