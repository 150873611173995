.card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 16px;
}

/* cardをhoverすると画像が大きくなる */

.card:hover img {
    transform: scale(1.2,1.2);
    transition: all 0.5s ease;
}

.image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.title {
    font-size: 1.5rem;
    margin: 16px;
}

.description {
    font-size: 1rem;
    margin: 16px;
    color: #333;
}
