.basic {
  background-color: #ffefd6;
  min-height: 100vh; /* ビューポートの高さの100% */
}

.scoreCard {
  padding: 0 auto;
  display: flex;
  flex-wrap: wrap;
  vertical-align: center;
  justify-content: center;
}
@media (max-width: 783px) {
  .scoreCardWrapper {
    width: 100%;
    max-width: 450px;
  }
}

.pageText{
  color :#7A5921;
  text-align: center;
}