.keyboard {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 20vh; /* Use viewport height for responsiveness */
    width: 80%; /* Use 80% of the parent container width */
    margin: 0 auto; /* Center the keyboard horizontally */
  }
  
  .key {
    position: relative;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-sizing: border-box; /* Include border in element's total width and height */
  }
  
  .whiteKey {
    height: 15vh; /* Use viewport height for responsiveness */
    background-color: #fff;
    z-index: 0;
  }
  
  .blackKey {
    position: absolute;
    width: 2.1%; /* Relative to whiteKey width */
    height: 48%; /* Relative to whiteKey height */
    background-color: #000;
    z-index: 1;
    top: 5vh; /* Align black keys to the top */
    left: 0%;
    transform: translateX(-0%);
    box-sizing: border-box; /* Include border in element's total width and height */
  }
  
  .highlightedWhite {
    background-color: lightpink;
  }
  
  .highlightedBlack {
    background-color: lightcoral;
  }
  
  .middleC {
    border: 2px solid red;
  }
  
  .middleCLabel {
    position: absolute;
    bottom: -3vh; /* Adjusted relative to viewport height */
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    color: red;
  }
  
  @media (max-width: 768px) {
    .whiteKey {
      height: 100%;
    }
  
    .blackKey {
      height: 65%;
      width: 2.4%;
      top: 0vh; /* Align black keys to the top */
    }
  
    .middleCLabel {
      bottom: -4vh;
    }
    .keyboard {
      width:100%;
      height: 13vw;
    }
  }
  