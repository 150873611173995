/*参照　https://qiita.com/kura07/items/e633b35e33e43240d363*/
/* https://dabohaze.site/react-youtube/ */

.main {
    display: flex; /* いったんflexにして大工事 */
    flex-flow: column;
    padding: 20px;
    padding-top: 50px;
    color :#7A5921;
}

.songInfoTop {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.shareLine {
    /*grid-area: 2 / 1 / 3 / 2;*/
    display: flex;
    width: 10%;
    min-width: 80px;
    justify-content: flex-start;
    vertical-align: top;
    padding-right: 15px;
}

.shareLine button {
    display: flex;
    min-height: 40px;
}

.myBookmark {
    /*grid-area: 2 / 5 / 3 / 6;*/
    display: flex;
    width: 10%;
    min-width: 80px;
    justify-content: flex-end;
    vertical-align: top;
}

.songInfo {
    /*grid-area: 2 / 1 / 3 / 6;*/
    display: flex;
    flex-flow: column;
    justify-self: center;
    width: 100%;
}

.title{
    font-weight: bold;
    text-align: center; /* テキストを中央揃え */
    align-self: center;
    overflow-wrap: break-word;
    width: 80%;
}

.artist{
    text-align: center;
    overflow-wrap: break-word;

}

.statusContainerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.statusContainer{
    /*grid-area: 3 / 1 / 4 / 6;*/
    display: flex;
    flex-flow: row nowrap;
    /*justify-content: space-between;*/
    max-width: 600px;
    padding: auto 0;
}



.accesibility .commentNum .bookmarkNum {
    width: 30%;
    min-width: 150px;
    text-align: center;
}

.versionAndButton {
    /*grid-area: 4 / 1 / 5 / 6;*/
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.version {
    /*grid-area: 4 / 1 / 5 / 2;*/
    overflow-wrap: break-word;
    max-width: 50%;
}

.topButtons {
    display: flex;
    flex-wrap: row nowrap;
    align-self: center;
}

.editButton {
    /*grid-area: 4 / 5;*/
    align-self: end;
    display: flex;
    width: 50px;
    justify-content: end;
    margin: 5px;
    height: 50px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 4px 4px 6px rgb(from var(--shadow-color) r g b / 0.3);
}
.deleteButton {
    /*grid-area: 4 / 4;*/
    align-self: end;
    display: flex;
    width: 50px;
    justify-content: start;
    margin: 5px;
    height: 50px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 4px 4px 6px rgb(from var(--shadow-color) r g b / 0.3);
}
.ableButton:hover {
    cursor: pointer;
}

.video {
    /*grid-area: 8 / 1;*/
    justify-self: start;
}

.videoGridItem {
    /*grid-area: 8 / 1 / 11 / 6;*/
    min-height: 100px;
}

.novideo {
    /*grid-area: 8 / 1 / 9 / 6;*/
    justify-self: center;
    justify-content: center;
    align-self: center;
    text-align: center;
    min-height: 100px;
    padding: 40px;
}

.voicerangeContainer {
    /*grid-area: 12 / 1 / 13 / 6;*/
    width: 90%;
    margin: 0 auto; /* Center horizontally */
    padding: 20px; /* Optional: Add some padding */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.commentEditButton {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-self: center;
    margin: 10px;
    justify-content: center;
}

.commentEdit {
    /*grid-area: 13 / 1 / 14 / 5;*/
    align-self: center;
    width: 90%;
    max-width: 800px;
}

.commentButton {
    /*grid-area: 13 / 5;*/
    align-self: center;
    padding: 5px;
    width: 88px;
}

.comment {
    /*grid-area: 14 / 1 / 15 / 6;*/
    align-self: center;
    width: 100%;
    max-width: 1000px;
}


.bookmark {
    border: none;
    outline: none;
    background: transparent;
    color :#7A5921;
    display: flex;
    justify-content: top;
    padding: 0;
}

.bookmark:hover {
    color : #7a5921de;
    cursor: pointer;
}

.SamuneContainer {
    position: relative;
    width: 100%;
    max-width: 535px;
    min-width: 0px;
}  
.SamuneContainer:before {
    content: "";
    display: block;
    padding-top: 141.4%;
}
.fixedSizeDiv {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /*枠線関連*/
    /*
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    */
    overflow: hidden; /* はみ出た部分を隠す */
}

.pdfPage {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
}
/*
.DlButtonContainer{
    text-align: center;
}
*/

.DlButtonContainer {
    margin: 5px;
    display: flex;
}

.DlButton {
    margin-left: 15px;
    margin-right: 15px;
}

.tableContainer {
    margin: auto;
    max-width: 800px; /* 表の最大幅 */
}
.table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 20px;
    box-shadow: 4px 4px 6px rgb(from var(--shadow-color) r g b / 0.3); /* 影の効果 */
    background-color: white;
}
.table td {
    padding: 10px 10px;
}
.key,.key1 {
    font-weight: bold;
    color :#7A5921;
    width: 35%; /* キーの幅を設定 */
    min-width: 4px;
    text-align: center;
}
.key1 {
    background-color: #f7f3ed;
}
.value,.value1 {
    color :#7A5921;
    width: 65%; /* バリューの幅を設定 */
    text-align: center;
    word-break: break-all;
}
.value1 {
    background-color: #f7f3ed;
}

.ytContainer {
    position: relative;
    width: 100%;
}
.ytContainer ::before {
    content: "";
    display: block;
    padding-top: 56.25%;
}
.iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* タグのボタン */
.tag {
    background: transparent;
    border: none;
    width: 100%;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
}

.tag p {
    color: var(--accent-color);
    text-decoration: underline;
    text-underline-offset: 2px;
}


.tagItem {
    float: left;
    padding: 0px 5px;
}

.tagButton {
    display: inline-block;
    background: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    font: inherit;
    margin-right: 8px; /* ボタンの右側にスペースを追加 */
}

.tagButton:hover,
.tagButton:focus {
    text-decoration: none;
}

.tagText {
    margin: 0px;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明の背景 */
    display: flex;
    justify-content: center; /* 中央寄せ */
    align-items: center; /* 中央寄せ */
    z-index: 999; /* 背景レイヤー */
}

.deleteModal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%; /* 必要に応じて調整 */
    max-width: 800px; /* 最大幅を設定 */
    z-index: 1000; /* モーダル本体のレイヤー */
    text-align: center; /* テキストを中央寄せ */
}

.modalContent {
    display: flex;
    flex-direction: column; /* 縦方向に並べる */
    align-items: center; /* 水平方向に中央寄せ */
    justify-content: center; /* 垂直方向に中央寄せ */
    width: 100%; /* 必要に応じて幅を調整 */
    gap: 20px; /* コンテンツ間の間隔を設定 */
    text-align: center; /* テキストを中央寄せ */
}

.buttonGroup {
    display: flex;
    justify-content: center; /* ボタンを中央寄せ */
    gap: 20px; /* ボタン間のスペース */
}

.accesibilityWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.accesibilityarea{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(0, 0, 0);
}
.accesibilityarea {
    top : 0px;
}
.accesibilityText {
    color :#7A5921;
    align-self: flex-end;
    
}

.commentWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.commentStatus {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.comment {
    top : 0px;
}
.commentIconWrapper {
    align-items: center;
    justify-content: center;
    margin: 5px;
    position: relative;
    display: flex;
}
.commentText {
    color :#7A5921;
    align-self: center;
    white-space: nowrap;
}
.commentNum {
    position: absolute;
    top: 3px;
    left: 47%;
    transform: translateX(-50%);
    color: rgb(255, 255, 255);
    /*background-color: var(--background-white);*/
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 1.5rem;
    z-index: 1;
}
.bookmarkNum {
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(255, 255, 255);
    /*background-color: var(--background-white);*/
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 1.5rem;
    z-index: 1;
}



/* PCなどの大画面用のスタイル */
@media (min-width: 900px) {
    .title {
        font-size: 35px;
    }
    .artist {
        font-size: 25px;
    }
    .statusContainer {
        font-size: 22px;
    }
    .container {
        /*grid-area: 6 / 1 / 7 / 6;
        display: grid;
        grid: auto auto/ auto minmax(30%, 50%);
        grid-gap: 10px;*/
        display: flex;
        flex-flow: row-reverse;
    }
    .tableArea {
        /*grid-area: 1 / 1 / 3 / 2;*/
        width: 50%;
    }   
    .dataArea {
        /*grid-area: 1 / 2;*/
        width: 50%;
        display: flex;
        flex-flow: column;
        justify-content: start;
        margin: 0 10px;
    }
    .SamuneContainerWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .SamuneContainer {
        /*position: relative;*/
        width: 500px;
        justify-self: center;
        align-self: center;
    }

    .DlButtonContainerWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .DlButtonContainer{
        /*grid-area: 2 / 2;*/
        justify-self: center;
        align-self: center;
    }
    .icon_accesibility {
        width: 50px;  /* 幅を24pxに設定 */
        height: 50px; /* 高さを24pxに設定 */
        object-fit: contain; /* 画像の比率を保ったままサイズ調整 */

    }
    .icon_comment {
        width: 45px;  /* 幅を24pxに設定 */
        height: 45px; /* 高さを24pxに設定 */
        object-fit: contain; /* 画像の比率を保ったままサイズ調整 */
    }
    .icon_bookmark {
        width: 45px;  /* 幅を24pxに設定 */
        height: 45px; /* 高さを24pxに設定 */
        object-fit: contain; /* 画像の比率を保ったままサイズ調整 */
    }
}





/* モバイルなどの小画面用のスタイル */
@media (max-width: 899px) {
    .title {
        font-size: 28px;
    }
    .container {
        /*grid-area: 6 / 1 / 7 / 6;
        display: grid;
        grid: auto auto auto/ auto;
        grid-gap: 10px;*/
        display: flex;
        flex-flow: column;
        justify-content: center;

    }
    .SamuneContainerWrapper {
        display: flex;
        justify-content: center;
    }
    .SamuneContainer {
        /*position: relative;*/
        min-width: 290px;
        width: 50%;
        /*max-width: 899px;*/
        justify-self: center;
        align-self: center;
    }
    .dataArea {
        width: 100%;
        justify-self: center;
        display: flex;
        flex-flow: column;
    }
    .DlButtonContainerWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .DlButtonContainer{
        /*grid-area: 2 / 2;*/
        justify-self: center;
        align-self: center;
    }
    .voicerangeContainer{
        width: 100%;
        padding: 10px;
    }
    .icon_accesibility {
        width: 50px;  /* 幅を24pxに設定 */
        height: 50px; /* 高さを24pxに設定 */
        object-fit: contain; /* 画像の比率を保ったままサイズ調整 */
        padding-bottom: 10px;
    }
    .icon_comment {
        width: 35px;  /* 幅を24pxに設定 */
        height: 35px; /* 高さを24pxに設定 */
        object-fit: contain; /* 画像の比率を保ったままサイズ調整 */
    }
    .icon_bookmark {
        width: 35px;  /* 幅を24pxに設定 */
        height: 35px; /* 高さを24pxに設定 */
        object-fit: contain; /* 画像の比率を保ったままサイズ調整 */
    }
    .commentNum {
        position: absolute;
        top: 0px;
        left: 47%;
        transform: translateX(-50%);
        color: rgb(255, 255, 255);
        /*background-color: var(--background-white);*/
        padding: 2px 5px;
        border-radius: 10px;
        font-size: 1.3rem;
        z-index: 1;
    }
    .bookmarkNum {
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        color: rgb(255, 255, 255);
        /*background-color: var(--background-white);*/
        padding: 2px 5px;
        border-radius: 10px;
        font-size: 1.3rem;
        z-index: 1;
    }
}

@media (max-width: 600px) {
    .versionAndButton {
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    .version {
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
    .icon_accesibility {
        width: 25px;  /* 幅を24pxに設定 */
        height: 25px; /* 高さを24pxに設定 */
        object-fit: contain; /* 画像の比率を保ったままサイズ調整 */
        padding-bottom: 2px;
    }
    .icon_comment {
        width: 20px;  /* 幅を24pxに設定 */
        height: 20px; /* 高さを24pxに設定 */
        object-fit: contain; /* 画像の比率を保ったままサイズ調整 */
    }
    .icon_bookmark {
        width: 20px;  /* 幅を24pxに設定 */
        height: 20px; /* 高さを24pxに設定 */
        object-fit: contain; /* 画像の比率を保ったままサイズ調整 */
    }
    .commentText {
        font-size: 12px;
    }
    .commentNum {
        position: absolute;
        top: 0px;
        left: 47%;
        transform: translateX(-50%);
        color: rgb(255, 255, 255);
        /*background-color: var(--background-white);*/
        padding: 2px 5px;
        border-radius: 10px;
        font-size: 0.7rem;
        z-index: 1;
    }
    .bookmarkNum {
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        color: rgb(255, 255, 255);
        /*background-color: var(--background-white);*/
        padding: 2px 5px;
        border-radius: 10px;
        font-size: 0.7rem;
        z-index: 1;
    }
}