:root {
    --background-white: #ffffff;
    --background-orange: #ffefd6;
    --accent-color: #f39800;
    --text-color: #7A5921;
    --shadow-color: #7a5921;
}

p {
    color: var(--text-color);
}

.card {
    /*border: 1px solid rgb(243, 152, 0);*/
    border-radius: 10px;
    /*overflow: hidden;*/
    box-shadow: 4px 4px 6px rgb(from var(--shadow-color) r g b / 0.3);
    background-color: var(--background-white);
}

.LinkToDetail {
    text-decoration: none;
    color: #fff;
}

.LinkToDetail:visited {
    color: #fff;
}

.titleBox {
    background-color: var(--accent-color);
    color: #fff;
}

.titleBox:visited {
    color: #fff;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
}

.artist {
    color: #fff;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
}


.bookmark {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.bookmarkIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookmarkButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px;
}


/*
.star {
    color: #333;
    padding: auto;
}
*/

.part {
    display: flex;
    flex-flow: row wrap;
    /*align-items: center;*/
    /*text-align: center;*/
    /*justify-content: center;*/
}

.infoTable {
    border-collapse: separate;
}

.infoTable tr {
    height: 40px;
}

.infoTable th {
    text-align: right;
    font-weight: normal;
}

.infoTable td {
    /*padding: 5px;*/
    align-items: left;
    text-align: left;
    border: none;
    padding-left: 1em;
}

.infoTable th p {
    margin: 0;
}

.infoTable td p {
    margin: 0;
}

/*
.vpWrapper{
    justify-content: center;
    align-items: center;
    text-align: center;
}*/

.partPerSexNumber {
    padding-right: 5px;
    margin: 0 0.5em 0 0;
}

.duration .tempo .difficulty .arranger {
    padding: 5px;
}

.footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    /*padding: auto;*/
}

.leftBottom {
    display: flex;
    flex-flow: column;
    justify-content: left;
}

.tag {
    background: transparent;
    border: none;
    max-width: 100%;
    text-align: left;
    cursor: pointer;
}

.tag p {
    color: var(--accent-color);
    text-decoration: underline;
    text-underline-offset: 2px;
}

.version {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rightBottom {
    display: flex;
    margin-top: 3px;

    .commentWrapper,
    .accesibility {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .comment {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/* PCなどの大画面用のスタイル */
@media (min-width: 784px) {
    .card {
        width: 330px;
        margin: 12px 16px;
        display: flex;
        flex-flow: column nowrap;
        height: 380px;
    }

    .titleBox {
        display: flex;
        flex-flow: row wrap;
        justify-content: left;
        text-align: left;
        color: #fff;
        margin: 0;
        padding: 0;
        border-radius: 10px 10px 0 0;
        height: 50px;
    }

    .titleBoxStr {
        width: 67%;
    }

    .title {
        font-size: 20px;
        margin: 10px;
        padding: 0;
        white-space: nowrap;
    }

    .artistBoxStr {
        width: 20%;
        height: 100%;
    }

    .artist {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 10px;
        vertical-align: middle;
        margin: 2px;
    }

    .bookmark {
        width: 13%;
    }

    .bookmarkButton {
        font-size: 25px;
    }

    .subItems {
        max-width: 100%;
        height: 320px;
        display: flex;
        flex-flow: column nowrap;
    }

    .infoTable {
        width: 100%;
        margin: 0.5em;
        font-size: 20px;
    }

    /*
    .partPerSexIcon{
        font-size: 35px;
        margin: 8px 0px;
    }*/

    .partPerSexNumber p {
        /*font-size: 35px;*/
        justify-content: center;
    }


    .footer {
        display: flex;
        justify-content: center;
    }

    .leftBottom {
        flex-basis: 80%;
        margin-top: 10px;
        margin: 5px;
    }

    .tagWrapper {
        display: flex;
        flex-flow: row wrap;
        overflow-y: hidden;
        justify-content: left;
        height: 20px;
        width: 100%;
        margin-bottom: 3px;
    }

    .tag {
        white-space: nowrap;
        overflow: hidden;
    }

    .tag p {
        font-size: 12px;
        margin: 0;
    }

    .version {
        width: 100%;
        height: 20px;
        margin: 2px;
    }

    .version p {
        font-size: 12px;
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .rightBottom {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 5px;
    }

    .comment {
        top : 0px;
    }

    .commentNum {
        position: absolute;
        top: 2px;
        left: 48%;
        transform: translateX(-50%);
        color: var(--accent-color);
        /*background-color: var(--background-white);*/
        padding: 2px 5px;
        border-radius: 10px;
        font-size: 1.0rem;
        z-index: 1;
    }


    .icon {
        height: 2.0rem;
    }
}


/* モバイルなどの小画面用のスタイル */
@media (max-width: 783px) {
    .card {
        width: 100%;
        margin: 20px 0;
        display: flex;
        flex-flow: column wrap;
    }

    .subItems {
        height: 220px;
    }

    .titleBox {
        display: flex;
        flex-flow: row;
        justify-content: left;
        align-items: center;
        text-align: left;
        color: #fff;
        width: 100%;
        border-radius: 10px 10px 0 0;
        height: 70px;
    }

    .titleBoxStr {
        vertical-align: middle;
        width: 70%;
    }

    .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;  /* 表示する行数 */
        -webkit-box-orient: vertical;
        font-size: 20px;
        margin: 5px 10px;
        padding: 0;
    }

    .artistBoxStr {
        vertical-align: middle;
        width: 20%;
        justify-content: center;
    }

    .artist {
        display: -webkit-box;
        -webkit-line-clamp: 3;  /* 表示する行数 */
        -webkit-box-orient: vertical;
        font-size: 10px;
        margin: 0px 2px 5px 2px;
    }

    .bookmarkIcon {
        margin: 5px 5px;
        bottom: 0px;
        justify-content: center;
    }

    .bookmarkButton {
        font-size: 30px;
    }

    .subItems {
        display: flex;
        flex-flow: row nowrap;
    }

    .infoTable {
        margin-left: 1em;
        width: 75%;
        padding: 5px 0px;
        font-size: 15px;
    }

    .infoTable th {
        padding: 0;
        margin: 0;
    }

    .infoTable th p {
        margin: 0;
        padding: 0;
    }


    .infoTable td p {
        margin: 0;
        padding: 0;
    }

    .partPerSexNumber p {
        /*font-size: 20px;*/
        justify-content: center;
    }

    .footer {
        padding: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: end;
        width: 25%;
        min-width: 70px;
    }

    .leftBottom {
        width: 100%;
        height: 75%;
        justify-content: end;
    }

    .tagWrapper {
        /*width: 75%;*/
        width: 100%;
        margin: 5px 0;
        display: flex;
        flex-flow: column wrap;
        overflow: hidden;
        justify-content: end;
    }

    .tag {
        width: 100%;
        overflow: hidden;
    }

    .tag p {
        font-size: 12px;
        margin: 0;
        overflow-wrap: anywhere;/*スマホ画面のときにどこでも折り返し、はみだしバグにならないため*/
    }

    .version p {
        font-size: 0.5em;
        margin: 5px;
    }

    .rightBottom {
        width: 100%;
        height: 25%;
    }

    .commentNum {
        position: absolute;
        top: 2px;
        left: 48%;
        transform: translateX(-50%);
        color: var(--accent-color);
        /*background-color: rgba(from var(--background-white) r g b / 0.5);*/
        padding: 2px;
        border-radius: 10px;
        font-size: 1.0rem;
        z-index: 1;
    }

    .icon {
        height: 2.0rem;
    }

}

@media (max-width: 355px) {
    .infoTable {
        margin-left: 0.5rem;
        width: 75%;
        padding: 5px 0px;
        font-size: 0.8rem;
    }
}