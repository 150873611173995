.container {
    max-width: 800px;
    margin: auto;
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.list {
list-style: none;
padding: 0;
}

.listItem {
    border: 1px solid #ccc;
    padding: 8px;
    margin: 8px 0;
    border-radius: 4px;
}

.button_wrapper {
    width:80%;
    margin: 20px;
    padding: 10px;
    display: flex;
    align-items: flex-start; /*上（左）揃え*/
    align-items: flex-end;   /*下（右）揃え*/
    align-items: center;     /*中央揃え*/
    align-items: baseline;   /*ベースラインを揃える*/
    align-items: stretch;    /*全ての要素の高さを揃える*/
}

.button {
    width: 20%;
    border: 2px solid #000;
    border-radius: 1;
    background: #fff;
    margin: 10px;
}
  
.button_clicked {
    width: 20%;
    color: #fff;
    background: #000;
    border-radius: 1;
    margin: 10px;
}

.input {
    width: 40%; /*親要素いっぱい広げる*/
    min-width: 100px;
    height: 30px;
    padding: 10px 15px; /*ボックスを大きくする*/
    font-size: 16px;
    border-radius: 3px; /*ボックス角の丸み*/
    border: 2px solid #ddd; /*枠線*/
    box-sizing: border-box; /*横幅の解釈をpadding, borderまでとする*/
    margin:10px;
}