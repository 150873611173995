.container {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

.bandname_console.error {
    color: red;
}

/* モーダル */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* 背景を半透明にする */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* モーダルコンテンツ */
.modal_content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* 影を追加 */
}

/* モーダルコンテンツ内のボタン */
.modal_content button {
    margin-right: 10px;
}