:root {
    --background-white: #ffffff;
    --background-orange: #ffefd6;
    --accent-color: #f39800;
    --text-color: #7A5921;
    --shadow-color: #7a5921;
}

.pageTitle {
    text-align: center;
    color: var(--text-color);
}

.container {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 4px 4px 4px rgba(from var(--shadow-color) r g b / 0.3);
}

.bandListWrapper {
    justify-content: center;
}

.bandList {
    list-style-type: none;
    margin: 5px;
    padding: auto;
    display: flex;
    flex-flow: row wrap;
    display: flex;
    justify-content:left;
    padding-inline-start: 0px;
}

.band {
    width: 235px;
}

.band button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
    margin: 10px;
    text-align: left;

    display: flex;
    flex-grow: 1;
    flex-flow: row nowrap;

    color: var(--text-color);
}

.listIcon svg{
    vertical-align: middle;
}

/* モーダル */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* 背景を半透明にする */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* モーダルコンテンツ */
.modal_content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* 影を追加 */
}

/* モーダルコンテンツ内のボタン */
.modal_content button {
    margin-right: 10px;
}

/* バンド追加ボタン */
.floatingButton {
    position: fixed;
    bottom: 40px;
    right: 30px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 5px;
    width: 150px;
    height: 30px;
    font-size: 18px;
    white-space: nowrap;
    cursor: pointer;
    box-shadow: 4px 4px 4px rgba(from var(--shadow-color) r g b / 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

.page {
    min-height: 100vh;
    background-color: #ffefd6;
}