.frame {
    display: flex;
    flex-direction: column;
    gap: 20px; /* 要素間の間隔 */
    padding: 20px;
    background: #fff;
    /*box-shadow: 0 0 10px rgba(0,0,0,0.1); */
    border-radius: 8px;
    width: 80%;
    margin: 0 auto;
    
}

.heading, .subheading {
color: #7A5921;
margin-bottom: 10px;
}

.group {
display: flex;
flex-direction: column;
gap: 10px;
}

.searchColumnText {
font-weight: bold; /* 太字の指定 */
}

.submitButton {
background-color: #007bff; /* ボタンの背景色 */
color: white; /* ボタンの文字色 */
padding: 10px 15px;
border: none;
border-radius: 5px;
cursor: pointer;
transition: background-color 0.3s ease;

/* ホバー時のスタイル */
&:hover {
    background-color: #0056b3;
}
}