.container {
    max-width: 800px;
    margin: auto;
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.list {
list-style: none;
padding: 0;
}

.listItem {
    border: 1px solid #ccc;
    padding: 8px;
    margin: 8px 0;
    border-radius: 4px;
}

.button {
    width: 20%;
    max-width: 200px;
    border: 2px solid #000;
    border-radius: 1;
    background: #fff;
    margin: 10px;
}
  
.button:hover {
    color: #fff;
    background: #000;
}