/* ヘッダー */
.header {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}

/* メインのスタイリング */
.main {
  display: flex;
  justify-content: space-between;
}

/* サイドバー */
.sidebar {
  flex: 1;
  background-color: #333;
  color: white;
  padding: 15px;
}

/* メインコンテンツ */
.content {
  flex: 3;
  padding: 15px;
  background-color: #f9f9f9;
}

/* フッター */
.footer {
  background-color: #f1f1f1;
  padding: 10px;
  text-align: center;
}

/* 画像とテキストのセクション */
.imageTextSection {
  display: flex;
  align-items: center;
}

.image {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}

.text {
  flex: 1;
}

/* レスポンシブな配置 */
.responsiveSection {
  display: flex;
  flex-wrap: wrap;
}

.responsiveItem {
  flex: 1;
  min-width: calc(50% - 10px);/* 画面幅50%から10px引いた値 */
  margin: 5px;
  text-align: center;
  background-color: #f1f1f1;
  padding: 20px;
}

/* スクリーンサイズによるレスポンシブ対応 */
@media screen and (max-width: 600px) { /* 画面サイズが600px以下 */
  .responsiveItem {
    min-width: 100%;
  }
}

/* ヘッダーのナビゲーションメニュー */
nav ul {
  margin:0 auto;
  padding: 0;
  text-align: center;
}

nav ul li {
  display: inline-block;
  min-width: 100px;
}

nav ul li:hover{
  text-decoration: underline;
}

/* サイドバーのアコーディオンメニュー */
aside details[open] {/* アコーディオンが開いているとき */
  margin-top: 0;
  padding-bottom: 10px;
}

aside details summary {
  /*display: block;*/
  transition: .3s;
  transform: translateY(10px);
  &:hover {
    cursor: pointer;
  }
}