body {
  color: #7A5921;
}

.responsiveBox {
  width: 100%;
  height: 300px;
  background-color: white;
  color: black;
  text-align: center;
  line-height: 300px;
}

.info {
  width:90%;
  margin:0 auto;
}

.info table {
  width:100%;
  margin-bottom:15px;    
}
.info th {
  text-align:left;
  width:25%;
  padding:8px 0;
  vertical-align:top;
}
.info td {
  width:75%;
  border: none;
}
.info td>*{
  vertical-align: middle;
}
.info input:not(input[type="checkbox"]), .info textarea {
  width:100%;
}
.info input:not(input[type="checkbox"]){
  height:35px;
}
.info input[type="checkbox"]{
  float: left;
}

.info select{
  float: left;
}
.info textarea {
  height:200px;
}
.info label {
  display:block;
}

/* モーダルコンテンツのスタイル */
.uploadModalContent {
  width: 80vw; /* 画面の幅の80% */
  height: 30vh; /* 画面の高さの80% */
  margin: auto; /* 中央揃え */
  display: flex; /* Flexboxを使用して中身を配置 */
  justify-content: flex-end; /* 水平方向の中央揃え */
  align-items: center; /* 垂直方向の中央揃え */
  overflow: auto; /* 内容がオーバーフローしたらスクロール可能に */
}
.uploadContent {
  /* ここにコンテンツのスタイルを書く。例えば: */
  width: 100%; /* 親要素（.container）に対して幅を100%に */
  height: 100%; /* 親要素（.container）に対して高さを100%に */
  justify-content: flex-end; /* 水平方向の中央揃え */
  /* 中身がコンテナのサイズに合うように調整 */
}
.errorMsgModalContent {
  width: 80vw; /* 画面の幅の80% */
  height: 80vh; /* 画面の高さの80% */
  margin: auto; /* 中央揃え */
  display: flex; /* Flexboxを使用して中身を配置 */
  justify-content: flex-end; /* 水平方向の中央揃え */
  align-items: center; /* 垂直方向の中央揃え */
  overflow: auto; /* 内容がオーバーフローしたらスクロール可能に */
}

.errorMsgContent {
  /* ここにコンテンツのスタイルを書く。例えば: */
  width: 100%; /* 親要素（.container）に対して幅を100%に */
  height: 100%; /* 親要素（.container）に対して高さを100%に */
  justify-content: flex-end; /* 水平方向の中央揃え */
  /* 中身がコンテナのサイズに合うように調整 */
  margin: 20px 0px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputDuration {
  min-width: 150px;
  max-width: 150px;
  display: flex;
}

.inputDuration p {
  margin-bottom: 3px;
}

.inputDurationWidth {
  margin: 3px;
}

.confirmContainer{
  align-items: center;
  text-align: center;
}

.centering{
  align-items: center;
  text-align: center;
}


.tagBox{
  display: flex;
  flex-flow: row;
  padding: auto;
  flex-wrap: wrap;
}

.tagInputBox{
  display: flex;
  flex-flow: row;
  padding: 5px;
}

.tagBoxButton {
  border: none;
  outline: none;
  background: transparent;
  align-items: center;
  align-content: center;
  min-width: 30px;
}

.tagAddButtonWrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: auto;
}

.tagAddButtonWrapper p {
  font-size: 18px;
  align-self: center;
  justify-self: center;
  margin: 0;
}

.tagAddButton {
  font-size: 20px;
  margin: 0;
}

.tagAddButton:hover {
  color: rgb(243, 152, 0);
}

.tagDeleteButton {
  font-size: 30px;
}

.tagDeleteButton:hover{
  color:rgb(243, 152, 0);
}

.YtBox{
  display: flex;
  flex-flow: column;
  padding: 5px;
  
}
.YtInputBox{
  display: flex;
  flex-flow: row;
  padding: 1px;
  align-items: center; /* 垂直方向の中央寄せ */
}
.YtInputBox > * {
  flex-grow: 1;
}

.YtBoxButton {
  border: none;
  outline: none;
  background: transparent;
  align-items: center;
  align-content: center;
  min-width: 30px;
  max-width: 30px;
}

.icon {
  color :#7A5921
}