:root {
    --background-white: #ffffff;
    --background-orange: #ffefd6;
    --accent-color: #f39800;
    --text-color: #7A5921;
    --shadow-color: #7a5921;
}

.card {
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 4px 4px 6px rgb(from var(--shadow-color) r g b / 0.3);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.comment {
    color: #333;
}