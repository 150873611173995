.videoList {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.YoutubeContainer {
position: relative;
width: 100%;
margin-bottom: 10px;
aspect-ratio: 16 / 9;
display: flex;
justify-content: center;
align-items: center;
max-width: 650px;
}


.iframe {
position: absolute;
top:auto;
left:auto;
width: 90%;
height: 90%;
align-items: center;
}
