:root {
    --background-white: #ffffff;
    --background-orange: #ffefd6;
    --accent-color: #f39800;
    --text-color: #7A5921;
    --shadow-color: #7a5921;
}

.button {
    background-color: #1fe0;
    border: 2px solid rgb(122, 89, 33);
    color: rgb(122, 89, 33);
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }

.primary {
    color: rgb(243, 152, 0);
    border: 2px solid rgb(243, 152, 0);
}

.primary:hover {
    color:rgba(243, 152, 0, .8);
    border: 2px solid rgba(243, 152, 0, .8);
}

.primary2 {
    background-color: rgb(243, 152, 0);
    color: #ffefd6;
    border: 2px solid rgba(243, 152, 0, .0);
    box-shadow: 4px 4px 6px rgb(from var(--shadow-color) r g b / 0.3);
}

.primary2:hover {
    background-color: rgba(243, 152, 0, .8);
}

.primary3 {
    background-color: rgb(243, 152, 0);
    color: #ffefd6;
    border: 2px solid rgba(243, 152, 0, .0);
}

.primary3:hover {
    background-color: rgba(243, 152, 0, .8);
}

.normal {
    color: rgb(122, 89, 33);
    border: 2px solid rgb(122, 89, 33);
}

.normal:hover {
    color: rgba(122, 89, 33, .8);
    border: 2px solid rgba(122, 89, 33, .8);
}

.console.error {
    color: red;
    border: 2px solid red;
}

.console.error:hover {
    color: rgba(255, 0, 0, .5);
    border: 2px solid rgba(255, 0, 0, .5);
  }

.disabled {
    color: lightgray;
    background-color: #ffefd6;
    border: 2px solid lightgrey;
    cursor: default;
}

.disabled:hover {
    color: lightgray;
    background-color: #ffefd6;
    border: 2px solid lightgrey;
    cursor: default;
}

