/* 基本スタイリング */
body {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  background-color: #f4f4f4;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* タイトル */
.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* APIエンドポイント表示部分 */
.endpoint {
  font-size: 18px;
  background-color: #e8e8e8;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

/* 説明文 */
.description {
  font-size: 16px;
  margin-bottom: 20px;
}

/* 入力フィールド & ボタン */
.input-container {
  margin-bottom: 20px;
}

.input-field {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}
