/* ヘッダー */
.header {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}

.navi {
  padding-left: 5px;
}

.navis {
  display: flex;
  justify-content: flex-start;

}




/* メインのスタイリング */
.main {
  display: flex;
  justify-content: space-between;
}

/* サイドバー */
.sidebar {
  flex: 1;
  background-color: #333;
  color: white;
  padding: 15px;
}

.contact a:link{color: green;}
.contact a:visited{color: green;}
.contact a:hover{color: green;}
.contact a:active{color: green;}




/* メインコンテンツ */
.content {
  flex: 3;
  padding: 15px;
  background-color: #f9f9f9;
}

/* フッター */
.footer {
  background-color: #f1f1f1;
  padding: 10px;
  text-align: center;
}

/* 画像とテキストのセクション */
.imageTextSection {
  display: flex;
  align-items: center;
}

.image {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}

.text {
  flex: 1;
}


/* レスポンシブな配置 */
.responsiveSection {
  display: flex;
  flex-wrap: wrap;
}

.responsiveItem {
  flex: 1;
  min-width: calc(50% - 10px);
  margin: 5px;
  text-align: center;
  background-color: #f1f1f1;
  padding: 20px;
}

/* スクリーンサイズによるレスポンシブ対応 */
@media screen and (max-width: 600px) {
  .responsiveItem {
    min-width: 100%;
  }
}