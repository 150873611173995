/** ドラッグエリア */
.dragzone {
  padding: 2rem;
  background: #ffffff;
  border: 5px dashed #f39800;
  color: #7A5921;
  text-align: center;
  min-height:50vh;
  display: flex; /* Flexboxを使う */
  flex-direction: column; /* 子要素を縦方向に並べる */
  justify-content: center; /* 子要素を垂直方向の中央に配置 */
  align-items: center; /* 子要素を水平方向の中央に配置 */
}
  /** ドラッグ中の状態 */
  .dragging {
    background: #ddd;
  }

.buttonWrapper{
  color: inherit; /* ラベルテキストの色を指定する */
  border: double 2px #7A5921;/* ラベルの背景色を指定する */
  padding: 10px; /* ラベルとテキスト間の余白を指定する */
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.buttonWrapper input {
  display: none;
}