/* タイトル用のスタイル */
.title {
  font-size: 2em; /* 例えば、20px */
  font-weight: bold;
  color: #333; /* ダークグレー */
  margin: 0.5em 0;
}

/* サブタイトル用のスタイル */
.subtitle {
  font-size: 1.5em;
  font-weight: normal;
  color: #666; /* ミディアムグレー */
  margin: 0.25em 0;
}

/* 本文用のスタイル */
.bodyText {
  font-size: 1em; /* 通常のフォントサイズ、例えば16px */
  color: #333;
  line-height: 1.6; /* 行の高さ */
}
